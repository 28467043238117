<template>
  <div class="relative inline-block" ref="dropdownMenu">
    <div v-if="open" @click="open = false" class="fixed pin"></div>
    <div role="button" class="inline-block select-none" @click="open = !open">
      <slot name="link"></slot>
    </div>
    <div class="cursor-pointer origin-top-right absolute min-w-48 mt-1 z-50" :class="{'right-0':pin==='r','left-0': pin ==='l', width: true}" v-show="open">
      <slot name="dropdown"  @click="close"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pin: {
      default: 'r',
      type: String
    },
    width: String
  },
  data () {
    return {
      open: false
    }
  },
  methods: {
    close () {
      this.open = false
    },
    documentClick (e) {
      let el = this.$refs.dropdownMenu
      let target = e.target
      if ((el !== target) && !el.contains(target)) {
        this.open = false
      }
    }
  },
  created () {
    document.addEventListener('click', this.documentClick)
  },
  destroyed () {
    // important to clean up!!
    document.removeEventListener('click', this.documentClick)
  }
}
</script>

<style>

</style>
