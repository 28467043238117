<template>
  <div>
    <dropdown-button ref="specialistMenu">
      <!--<div slot="link" class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-2 py-1 bg-white border-blue-700 text-sm font-medium text-blue-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
        <div class="mr-1">{{button_text ? button_text : 'Share'}}</div>
        <svg class="-mr-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
        </svg>
      </div>-->
      <div slot="link" class="inline-flex items-center rounded-md px-3 py-2 border border-blue-200 text-sm font-medium leading-4 text-blue-800 bg-blue-100 hover:bg-blue-200 hover:border-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
        {{button_text ? button_text : 'Share'}}
      </div>
      <div slot="dropdown" class="text-right origin-top-right absolute right-0 mt-0 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none border" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
        <div class="py-1" role="none">
          <button @click="nativeShare" class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-200" role="menuitem" tabindex="-1" id="menu-item-0">Share to Facebook</button>
          <a :href="`mailto:?subject=Check out this show: ${encodeURIComponent(show_name)}&body=${encodeURIComponent(url)}`" target="_blank" class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-200" role="menuitem" tabindex="-1" id="menu-item-1">Email Link</a>
          <!--<button @click="nativeShare" class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-200" role="menuitem" tabindex="-1" id="menu-item-0">Native Share</button>-->
        </div>
        <div class="px-4 py-3" role="none">
          <p class="text-xs font-medium link truncate" v-tooltip="'Click to copy'" role="none" @click="copyToClipboard(url)">
            Copy Link</p>
        </div>
      </div>
    </dropdown-button>
  </div>
</template>

<script>
export default {
  props: {
    url: String,
    show_name: String,
    button_text: {
      type: String,
      default: ''
    }
  },
  data () {
    return {

    }
  },
  methods: {
    copyToClipboard (text) {
      navigator.clipboard.writeText(text)
    },
    facebookShare () {
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${this.url}&amp;src=sdkpreparse&display=popup`, 'popup', 'width=600,height=600')
      return false
    },
    nativeShare () {
      if (navigator.share) {
        navigator.share({
          title: 'tot;e',
          text: this.show_name,
          url: this.url
        })
        /* .then(() => // console.log('Successful Share'))
        .catch((error) => // console.log('Error Sharing', error)) */
      } else {
        this.facebookShare()
      }
    }
  },
  computed: {
  }
}
</script>

<style>

</style>
