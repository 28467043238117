<template>
  <div :class="{'overflow-x-auto':useOverflow}">
    <table :class="{'w-full': true, 'overflow-auto': true, 'table-styled': true, 'table-hover': hover}">
      <thead v-if="showHeaders" class="bg-gray-400 border-b border-t border-gray-400">
        <th v-if="allowDraggable"></th>
        <th v-for="f in tableFields" :key="f.key" :class="{'text-sm': true, 'font-medium': true, 'p-4': true,'hidden':f.showMobile==='NO', 'sm:table-cell':true, 'text-center': f.position==='center', 'text-left': f.position==='left', 'text-right': f.position==='right'}">
          {{f.label ? f.label : f.key.replace(/_/g,' ').toUpperCase()}}
        </th>
      </thead>
      <draggable tag="tbody" v-model="tableDataC" ghost-class="ghost" handle=".handle" class="bg-white divide-y divide-gray-200">
        <tr v-for="(d, i) in tableDataC" :key="i">
          <td v-if="allowDraggable"><span class="text-xl handle draggable">&#9776;</span></td>
          <td v-for="f in tableFields" :key="f.key" :class="{'p-2':!condensed, 'md:p-4': !condensed, 'p-1':condensed, 'border-t': border, 'sm:table-cell':true, 'hidden':f.showMobile==='NO'}">
            <slot :name="f.key" v-bind:item="d">
              {{d[f.key]}}
            </slot>
          </td>
        </tr>
        <tr :key="`d-${i}`" class="row-detail" v-if="hasDetails">
          <td :colspan="tableFields.length+1">
            <slot name="row-details" v-bind:item="d">
            </slot>
          </td>
        </tr>
      </draggable>
    </table>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  components: {
    draggable
  },
  data () {
    return {
    }
  },
  props: {
    tableData: Array,
    tableFields: Array,
    hover: Boolean,
    showHeaders: {
      type: Boolean,
      default: true
    },
    allowDraggable: {
      type: Boolean,
      default: false
    },
    border: {
      type: Boolean,
      default: true
    },
    condensed: {
      type: Boolean,
      default: false
    },
    hasDetails: {
      type: Boolean,
      default: false
    },
    useOverflow: {
      type: Boolean,
      default: false
    },
    isDraggable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    tableDataC: {
      get: function () {
        return this.tableData
      },
      set: function (value) {
        this.$emit('orderUpdated', value)
      }
    }
  },
  methods: {}
}
</script>

<style>
.table-styled .lead {
  text-transform: capitalize;
}
.table-styled thead {
  background-color: HSL(200, 33%, 94%);
  font-size: 14px;
  font-weight: semibold;
  text-transform: uppercase;
  color: #4a4a4a;
}
.table-styled td {
  /*padding: 15px;*/
  border-top-color: #e6e6e6;
}
.table-styled .btn-link {
  /*color: rgb(247, 249, 250);*/
  color: rgb(189, 192, 194);
}
.table-styled tr:hover .btn-link {
  color: #0954AB;
}
.table-styled .btn-link:hover {
    text-decoration: underline !important;
}
.table-hover tbody tr:hover {
    background-color: rgba(0,0,0,.075);
}

</style>
